import React, { useState } from "react";
import axios from "axios";
import { IoPerson } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { baseApiUrl } from "../../utils/constants";
import { logout } from "../../redux/Features/Auth";
import Alert from "../../components/Alert";
import { IoLogOut } from "react-icons/io5";
import ModalOverlay from "../../components/ModalOverlay";
import Feedback from "./Feedback";
import { MdFeedback } from "react-icons/md"
import LiveChat from "../../components/LiveChat";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenFeedback, setIsOpenFeedback] = useState(false);

  const { userDetails } = useSelector((state) => state.user);
  // const { examDetails } = useSelector((state) => state.exam);
  let examDetails = null;

  console.log(userDetails.exam_id, "EXAM ID");

  // const handleStartExam = async () => {
  //   // if (!userDetails.token) {
  //   //   Alert("warning", "No Access Token");
  //   //   setIsOpenModal(true);
  //   //   return;
  //   // }
  //   try {
  //     const res = await axios.get(`${baseApiUrl}/exam.php`);

  //     console.log(res.data, "EXAM DATA");

  //     // check for reoccurring exam
  //     const reoccurringExam = res.data.filter(
  //       (exam) =>
  //         exam.category === "Multi Choice - Multi Attempt" &&
  //         exam.group === userDetails?.group
  //     );

  //     if (reoccurringExam.length > 0) {
  //       examDetails = reoccurringExam[0];
  //       dispatch(setExamDetails(examDetails));
  //       const today = new Date();
  //       const expire = new Date(examDetails?.end);

  //       if (expire.toDateString() === today.toDateString()) {
  //         Alert("info", "Exam Ended");
  //         return;
  //       }

  //       const sub = {
  //         id: examDetails?.exam_id,
  //         sub1: userDetails?.s1,
  //         sub2: userDetails?.s2,
  //         sub3: userDetails?.s3,
  //         sub4: userDetails?.s4,
  //       };

  //       const res = await axios.post(`${baseApiUrl}/start.php`, sub);

  //       if (res.data.status === "success") {
  //         const questions = res.data.questions;

  //         // Group the questions by course
  //         const groupedQuestions = {};
  //         questions.forEach((question) => {
  //           if (!groupedQuestions[question.course]) {
  //             groupedQuestions[question.course] = [];
  //           }
  //           groupedQuestions[question.course].push(question);
  //         });

  //         // Shuffle the groups
  //         const shuffledGroups = Object.values(groupedQuestions);
  //         for (let i = shuffledGroups.length - 1; i > 0; i--) {
  //           const j = Math.floor(Math.random() * (i + 1));
  //           [shuffledGroups[i], shuffledGroups[j]] = [
  //             shuffledGroups[j],
  //             shuffledGroups[i],
  //           ];
  //         }

  //         // Flatten the array
  //         const shuffledQuestions = shuffledGroups.flat();

  //         console.log(shuffledQuestions);
  //         dispatch(setQuestions(shuffledQuestions));
  //       } else {
  //         Alert(res.data.status, res.data.message);
  //       }
  //       // navigate(`/instruction/${examDetails?.exam_id}`);
  //       navigate(`/instruction/${examDetails?.exam_id}`);
  //     } else {
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleStartExam = () => {
    navigate("/select");
  };

  const handleViewResult = async () => {
    // if (!userDetails.token) {
    //   Alert("warning", "No Access Token");
    //   setIsOpenModal(true);
    //   return;
    // }
    navigate("/result");
  };

  const handleLogout = async () => {
    const username = userDetails?.examno;
    console.log(username);

    const res = await axios.put(`${baseApiUrl}/login.php`, {
      username,
    });
    console.log(res);
    if (res.data.status === "success") {
      dispatch(logout());
      Alert(res.data.status, res.data.message);
      navigate("/");
    }

    return;
  };

  const handleOpenModal = () => {
    if (!!userDetails.token) return;
    setIsOpenModal(!isOpenModal);
  };

  const handleFeedbackModal = () => {
    setIsOpenFeedback(!isOpenFeedback);
  }

  return (
    <main className="h-screen w-screen bg-gray-200 flex flex-col">
      <LiveChat />
      <>
        <button
          onClick={handleFeedbackModal}
          className="absolute md:bottom-40 bottom-24 md:right-12 right-6 border-2 border-green-700 flex gap-2 hover:bg-green-300 text-white font-bold text-sm rounded-md px-3 py-1 focus:outline-none"
        >
          <MdFeedback className="text-xl text-green-700" />
          <p className="text-green-700 font-bold">Feedback</p>
        </button>

        {isOpenFeedback && (
          <ModalOverlay>
            <Feedback
              closeModal={handleFeedbackModal}
            />
          </ModalOverlay>
        )}
      </>
      <section className=" w-full h-24 flex justify-end md:justify-between items-center md:px-10">
        <div className="hidden md:flex flex-col items-start">
          <p>Hello</p>
          {userDetails && (
            <p className="font-bold text-3xl text-blue-900">
              {userDetails?.firstname} {userDetails?.lastname}
            </p>
          )}
        </div>
        <div className="flex gap-4 items-center">
          {/* <>
            <button
              disabled={!!userDetails.token}
              onClick={handleOpenModal}
              className={`border-2 hidden ${
                userDetails?.token
                  ? " border-green-800 hover:bg-green-300 text-green-800"
                  : " border-red-800 hover:bg-red-300 text-red-800"
              } font-bold text-sm rounded-md capitalize py-1 px-2 focus:outline-none flex items-center flex-row-reverse gap-2`}
            >
              <IoKey className="text-2xl" />
              {userDetails?.token === null || ""
                ? "Click to add token"
                : userDetails?.token}
            </button>

            {isOpenModal && (
              <ModalOverlay>
                <AddToken
                  closeModal={handleOpenModal}
                  user={userDetails?.examno}
                />
              </ModalOverlay>
            )}
          </> */}
          <div className="h-16 w-16 flex items-center">
            {userDetails?.avatar ? (
              <>
                <img
                  src={`${baseApiUrl}/images/${userDetails?.avatar}`}
                  alt="User Avatar"
                  className="w-[65%] h-[65%] rounded-md shadow-md"
                />
              </>
            ) : (
              <div className="bg-white shadow-md rounded-xl p-2 flex items-center justify-center">
                <IoPerson className="text-blue-800 text-3xl" />
              </div>
            )}
          </div>
          <button
            onClick={handleLogout}
            className="hidden md:flex gap-1 items-center text-red-500 font-bold rounded-md uppercase  hover:underline"
          >
            logout <IoLogOut />
          </button>
        </div>
      </section>
      <section className="h-full flex flex-col">
        <div className="md:hidden h-1/4 md:h-full w-full flex flex-col justify-center md:justify-end">
          <div className="md:hidden flex flex-col px-5 items-start">
            <p>Hello</p>
            {userDetails && (
              <p className="font-bold text-3xl text-blue-900">
                {userDetails?.firstname} {userDetails?.lastname}
              </p>
            )}
          </div>
        </div>
        <div className="flex items-start md:items-center md:justify-center gap-4 md:gap-20 px-5 py-8 h-full w-full">
          <div
            role="button"
            onClick={handleStartExam}
            className="bg-gradient-to-br from-green-800 to-blue-800 p-4 w-2/4 h-2/4 md:h-[250px] md:w-[250px] rounded-2xl shadow-md flex items-center justify-center cursor-pointer"
          >
            <h2 className="text-white font-extrabold text-3xl md:text-5xl uppercase bg-transparent">
              take <br /> exam
            </h2>
          </div>
          <div
            role="button"
            onClick={handleViewResult}
            className="bg-gradient-to-tr from-blue-800 to-green-800 p-4 w-2/4 h-2/4 md:h-[250px] md:w-[250px] rounded-2xl shadow-md flex items-center justify-center cursor-pointer"
          >
            <h2 className="text-white font-extrabold text-3xl md:text-5xl uppercase bg-transparent">
              view <br /> results
            </h2>
          </div>
        </div>
      </section>
      <section></section>
    </main>
  );
};

export default Details;
