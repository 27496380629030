export default function ModalOverlay({ children,className, closeModal = null }) {
  return (
    <div
      className={`fixed z-30 inset-0 overflow-y-auto ${className}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      onClick={() => {
        if (closeModal && typeof closeModal === "function") {
          closeModal();
        }
      }}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          {children}
        </div>
      </div>
    </div>
  );
}
